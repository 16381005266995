




























import './styles/MemberHeader.scss';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const NSProject = namespace('storeProject');

@Component({
    name: 'MemberHeader',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseRespondsCounter: () => import('@components/BaseRespondsCounter'),
    },
})
export default class MemberHeader extends Vue {
    @NSProject.Getter('projectId') projectId!: number;

    goToProjectTeam(): void {
        this.$router.go(-1);
    }

    goToResponds(): void {
        this.$router.push(`/project/${this.projectId}/responds`);
    }

    goTo(path: string): void {
        this.$router.push({ path });
    }
}
